<template>
  <div class="group-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>群組管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
            群組列表
          </h4>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-12 col-xl-6">
            <div class="d-flex align-items-center mb-2 mb-xl-0">
              <h6 class="mb-0 mr-2 flex-shrink-0">群組狀態</h6>
              <AppMultiSelect
                v-model="selectedStatus"
                :options="statusOptions"
                @input="fetchGroups(currentPage)"
              />
            </div>
          </div>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0"
              variant="primary"
              @click="exportGroups"
              ><i class="fa fa-plus"></i>下載群組清單
            </b-button>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋群組"
                v-model="keyword"
                v-on:keyup.enter="fetchGroups(currentPage)"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchGroups(currentPage)"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          responsive
          striped
          hover
          :items="groups"
          :fields="fields"
          :no-local-sorting="true"
        >
          <template #cell(name)="data">
            <div class="mb-1">
              {{ data.item.name }}
            </div>
            <div>
              {{ data.item.group_number }}
            </div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(picture_url)="data">
            <b-img-lazy
              v-bind="mainProps"
              v-show="!!data.item.picture_url"
              rounded
              :src="data.item.picture_url"
            ></b-img-lazy>
          </template>
          <template #cell(status)="data">
            <div class="mb-1">{{ statusEnum(data.item.status) }}</div>
            <div>{{ data.item.type ? data.item.type.name : "" }}</div>
          </template>
          <template #cell(bot_leave_and_datetime)="data">
            <p>
              {{
                !!data.item.bot_join_datetime
                  ? formatDate(data.item.bot_join_datetime)
                  : ""
              }}
            </p>
            <p
              style="color: red"
              id="popover-target"
              :title="`機器人已於${formatDate(
                data.item.bot_leave_datetime
              )}退出群組`"
              v-b-popover.hover.bottomright="
                '如要繼續在此群組中使用團購功能，請先將機器人加回群組'
              "
            >
              {{
                !!data.item.bot_leave_datetime
                  ? formatDate(data.item.bot_leave_datetime)
                  : ""
              }}
            </p>
          </template>
          <template #cell(updated_at)="data">
            {{ formatDate(data.item.updated_at) }}
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="handleGroupCheck(data.item)"
              style="margin-right: 10px"
            >
              <i class="fa fa-refresh fa-lg"></i>
            </b-button>
            <b-button
              @click="handleGroupUrlQrcode(data.item)"
              style="margin-right: 10px"
            >
              <i class="fa fa-qrcode fa-lg"></i>
            </b-button>

            <b-dropdown
              id="dropdown-left"
              class="m-2"
              text="動作"
              :disabled="!isActionButtonEnabled"
            >
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_VIEW_DETAIL])"
                :to="{
                  name: 'ConversationRecordList',
                  params: { group_id: data.item.id },
                }"
              >
                查看7天內對話紀錄
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_VIEW_DETAIL])"
                :to="{
                  name: 'GroupCustomerList',
                  params: { group_id: data.item.id },
                }"
              >
                查看群組用戶名單
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_EDIT_LINK])"
                v-b-modal="'modal-edit-group-url'"
                @click="editGroupUrl(data.item)"
              >
                編輯群組連結
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_EDIT_BRANCH])"
                v-b-modal="'modal-edit-group-branch'"
                @click="editGroupBranch(data.item)"
              >
                編輯所屬分店
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_LEAVE])"
                @click="handleLeaveGroup(data.item)"
              >
                機器人退出群組
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission([consts.GROUP_DELETE_LOGS])"
                @click="handleDeleteGroup(data.item)"
              >
                刪除群組紀錄
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #head(member_count)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('member_count')"
              >
                人數

                <sort-caret
                  class="ml-2"
                  :status="
                    sortBy === 'member_count'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
          <template #head(branch)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('branch_id')"
              >
                所屬分店/代號

                <sort-caret
                  class="ml-2"
                  :status="
                    sortBy === 'branch_id'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchGroups"
          ></b-pagination>
        </div>
      </b-overlay>
    </b-card>
    <!-- edit group branch modal -->
    <b-modal
      id="modal-edit-group-branch"
      title="編輯所屬分店"
      centered
      @hidden="resetModal"
      @ok="handleEditGroupBranch"
    >
      <h4>{{ currentGroup.name }}</h4>
      <p>
        請務必確認所選擇的分店正確，確認送出後將影響後台帳號可看到的團購列表
      </p>

      <form ref="form" @submit.stop.prevent="handleEditGroupBranch">
        <b-form-group>
          <b-form-select
            v-model="groupBranchId"
            :options="branches"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-form-select
            v-model="groupTypeId"
            :options="groupTypes"
            required
          ></b-form-select>
        </b-form-group>
      </form>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          取消編輯
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          確認送出
        </b-button>
      </template>
    </b-modal>
    <!-- edit group url modal -->
    <b-modal
      id="modal-edit-group-url"
      title="編輯群組連結"
      centered
      @hidden="resetModal"
      @ok="handleEditGroupUrl"
    >
      <h4>{{ currentGroup.name }}</h4>
      <form ref="form">
        <b-form-group>
          <b-form-input
            id="group-url-input"
            placeholder="https://line.me/R/ti/g/ ◯ ◯ ◯"
            v-model="groupUrl"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          取消編輯
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          確認送出
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import store from "@/store";
import groupApi from "@/apis/group";
import branchApi from "@/apis/branch";
import groupTypeApi from "@/apis/group-type";
import RoleChecker from "@/utils/RoleChecker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import SortCaret, { SORT_CARET_STATUS } from "@/components/SortCaret";
import AppMultiSelect from "@/components/AppMultiSelect";
const STATUS_DISABLED = 3;

export default {
  components: {
    SortCaret,
    AppMultiSelect,
  },
  data() {
    return {
      SORT_CARET_STATUS,
      consts,
      showLoading: true,
      sortBy: "",
      sortDesc: false,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      fields: [
        {
          key: "status",
          label: "群組狀態",
          class: "text-center",
        },
        {
          key: "picture_url",
          label: "群組照片",
          class: "text-center",
        },
        {
          key: "name",
          label: "群組名稱/ID",
          class: "text-center",
        },
        {
          key: "member_count",
          label: "人數",
          class: "text-center",
        },
        {
          key: "branch",
          label: "所屬分店/代號",
          class: "text-center",
        },
        {
          key: "bot_leave_and_datetime",
          label: "機器人加入時間/退出時間",
          class: "text-center",
        },
        {
          key: "updated_at",
          label: "更新時間",
          class: "text-center",
        },
        {
          key: "actions",
          label: "功能",
          class: "text-center",
        },
      ],
      groups: [],
      groupTypes: [],
      branches: [],
      currentGroup: {},
      updateGroup: {},
      groupUrl: null,
      groupBranchId: null,
      groupTypeId: null,
      groupQrcode: null,
      selectedStatus: [], // default selected status 1
      keyword: null,
      permissionChecker: new PermissionChecker(),
    };
  },
  mounted() {
    this.selectedStatus =
      this.statusOptions.length > 0 ? [this.statusOptions[0]] : [];
    this.fetchGroups();
    this.fetchGroupTypes();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    statusOptions() {
      let statusOptions = [];
      const checker = new PermissionChecker();
      if (checker.check([consts.GROUP_LIST_VIEW_1])) {
        statusOptions.push({ text: "已綁定分店", value: "1" });
      }

      if (checker.check([consts.GROUP_LIST_VIEW_2])) {
        statusOptions.push({ text: "未綁定分店", value: "0" });
      }

      if (checker.check([consts.GROUP_LIST_VIEW_3])) {
        statusOptions.push({ text: "POYA團購已退出", value: "2" });
      }
      return statusOptions;
    },
    isActionButtonEnabled() {
      // 如果都沒有權限就讓動作按鈕停用
      return this.permissionChecker.checkAny([
        consts.GROUP_VIEW_DETAIL,
        consts.GROUP_EDIT_LINK,
        consts.GROUP_EDIT_BRANCH,
        consts.GROUP_LEAVE,
        consts.GROUP_DELETE_LOGS,
      ]);
    },
    selectedStatusValue() {
      return this.selectedStatus.map(({ value }) => value).join(",");
    },
  },
  methods: {
    async fetchGroupTypes() {
      const { data } = await groupTypeApi.list();

      this.groupTypes = [
        ...data.data.map((groupType) => {
          return {
            text: groupType.name,
            value: groupType.id,
          };
        }),
      ];
    },
    hoverData() {
      return `<i class="fa fa-hand-pointer-o" aria-hidden="true"></i>`;
    },
    async fetchGroups(page) {
      this.showLoading = true;

      try {
        const params = {
          page: page || this.currentPage,
        };
        if (this.keyword) params.keyword = this.keyword;
        if (!this.sortDesc) params.asc = "";
        if (this.sortBy) params.sort_by = this.sortBy;
        this.selectedStatus.length !== 0
          ? (params.status = this.selectedStatusValue)
          : (params.status = "-1");

        const { data } = await groupApi.getGroups(params);

        this.groups = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    exportGroups() {
      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/admin/organizations/" +
        this.organization.id +
        "/groups/export?token=" +
        store.state.auth.token;
      if (this.keyword) url = url + "&keyword=" + this.keyword;
      this.selectedStatus.length !== 0
        ? (url = url + "&status=" + this.selectedStatusValue)
        : (url = url + "&status=-1");

      window.open(url);
    },
    statusEnum(status) {
      if (status === 0) {
        return "未綁定分店";
      }
      if (status === 1) {
        return "已綁定分店";
      }
      if (status === 2) {
        return "POYA團購已退出";
      }
    },
    resetModal() {
      this.currentGroup = {};
      this.groupUrl = "";
      this.groupBranchId = "";
      this.groupTypeId = "";
      this.groupQrcode = "";
    },
    editGroupUrl(group) {
      this.currentGroup = group;
      this.groupUrl = group.group_url;
    },
    async handleEditGroupUrl(bvModalEvt) {
      bvModalEvt.preventDefault();

      await this.goUpdateGroup(this.currentGroup.id, {
        group_url: this.groupUrl,
      });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-group-url");
      });

      this.resetModal();

      await this.fetchGroups();
    },
    editGroupBranch(group) {
      console.log(group);
      this.currentGroup = group;
      this.groupBranchId = group.branch ? group.branch.id : null;
      this.groupTypeId = group.group_type ?? null;
      this.fetchMyBranch();
    },
    async fetchMyBranch() {
      if (this.branches.length > 0) {
        return;
      }

      try {
        const { data } = await branchApi.getCurrentBelowBranches();
        data.data.forEach((branch) => {
          if (branch.org_id === this.organization.id) {
            var text = `${branch.branch_code} ${branch.name}`;
            if (branch.level > 1) {
              text = "-".repeat(branch.level - 1) + " " + text;
            }
            this.branches.push({
              text: text,
              value: branch.id,
            });
          }
        });
      } catch (error) {
        console.log(error);
        alert("取得分店資料錯誤");
      }
    },
    async handleEditGroupBranch(bvModalEvt) {
      bvModalEvt.preventDefault();
      if(!this.groupTypeId) {
        alert('請選擇群組類型');
        return;
      }

      await this.goUpdateGroup(this.currentGroup.id, {
        branch_hashid: this.groupBranchId,
        group_type_hashid: this.groupTypeId,
      });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-group-branch");
      });

      this.resetModal();

      await this.fetchGroups();
    },
    async goUpdateGroup(groupId, payload) {
      try {
        let response = await groupApi.updateGroup(groupId, payload);

        if (response.status !== 200) {
          alert("更新群組錯誤");
        }
      } catch (error) {
        alert("更新群組錯誤");
      }
    },
    handleGroupUrlQrcode(group) {
      if (!group.group_url) {
        this.$swal({
          type: "warning",
          title: "請設定群組連結",
          text: "請設定群組連結，群組團購機制才能正常運作",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "前往設定",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "不設定",
        }).then(async (result) => {
          if (result.dismiss === "cancel") {
            this.editGroupUrl(group);
            this.$bvModal.show("modal-edit-group-url");
          }
        });
        return;
      }

      this.groupQrcode =
        process.env.VUE_APP_API_BASE_URL +
        "/admin/organizations/" +
        this.organization.id +
        "/groups/" +
        group.id +
        "/qrcode?token=" +
        store.state.auth.token;
      this.$swal({
        title: group.name,
        text: group.group_url,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#3ba810",
        cancelButtonText: "OK",
        imageUrl: this.groupQrcode,
        imageAlt: "loading...",
      });
    },
    async handleGroupCheck(group) {
      await groupApi.checkGroup(group.id);
      this.$swal({
        title: "更新完成",
        showConfirmButton: true,
        cancelButtonColor: "#3ba810",
        cancelButtonText: "確定",
      }).then((result) => {
        if (result.value) {
          window.location.reload();
        }
      });
    },
    handleLeaveGroup(group) {
      this.$swal({
        title: "確定要將機器人退出群組？",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>群組名稱：${group.name}</div>
              <div>群組ID：${group.group_number}</div>
            </div>
            <div>機器人退出後，群組將無法繼續使用團購功能</div>
          </div>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "確認退出",
        confirmButtonText: "不要退出",
      }).then(async (result) => {
        if (result.dismiss === "cancel") {
          try {
            let response = await groupApi.leaveGroup(group.id);

            if (response.status !== 200) {
              this.$swal("退出群組錯誤", "", "warning");
              return;
            }
            this.$swal("已退出", "", "success");
          } catch (error) {
            this.$swal("退出群組錯誤", "", "warning");
          }

          await this.fetchGroups();
        }
      });
    },
    handleDeleteGroup(group) {
      this.$swal({
        title: "確定要刪除群組嗎？",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>群組名稱：${group.name}</div>
              <div>群組ID：${group.group_number}</div>
            </div>
            <div>本功能只刪除後台該群組紀錄，不會刪除 Line 群組。</div>
          </div>`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        cancelButtonText: "刪除群組",
        confirmButtonText: "不要刪除",
      }).then(async (result) => {
        if (result.dismiss === "cancel") {
          await this.goUpdateGroup(group.id, {
            status: STATUS_DISABLED,
          });
          this.$swal("已刪除", "", "success");

          await this.fetchGroups();
        }
      });
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.currentPage = 1;
      this.fetchGroups();
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.group-list {
  .nav-tabs .nav-link.active,
  .table thead {
    background: rgba(255, 98, 88, 0.2);
  }
}
</style>
